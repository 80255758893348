<template>
    <div id="checkOrder" class="container-fluid m-0 p-0">
        <div class="row m-0 p-3 justify-content-center">
            <div class="col-12 col-lg-12 m-0 p-0 shadow   bg-white rounded">
                <div class="row m-0 p-4">
                    <div class="col-12 mx-0 mb-3 mb-xl-0">
                        <p class="font-1-2s font-600">Check Status</p>
                    </div>
                </div>
                <div class="row m-0 pl-4 pb-4">
                    <div class="col-12 mx-0 mb-3 mb-xl-0">
                        <p class="px-0 font-700 w-100 font-0-9s" style="display: flex">
                            จำนวน &nbsp;
                            <span class="text-main">
                                {{ new Intl.NumberFormat().format(totalRows) }}
                            </span>
                            &nbsp; รายการ
                        </p>
                        <b-table hover outlined show-empty class="form-control-sm font-0-8s" :items="items"
                            :fields="fields" head-variant="light" :current-page="page_num" :per-page="page_size"
                            :tbody-tr-class="rowClass">
                            <template #cell(show_details)="row">
                                <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                                    {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
                                </b-button>
                            </template>

                            <template #row-details="row">
                                <TableDetail :items="row" :key="row.Name" v-if="!row.detailsShowing"
                                    class="font-1-2s bg-white" />
                            </template>
                        </b-table>

                        <div class="col-12">
                            <div class="row justify-content-end">
                                <div class="col-6 col-xl-2 input-group-prepend input-group-sm">
                                    <select class="custom-select" v-model="page_size">
                                        <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                                            {{ e }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-6 col-xl-3">
                                    <b-pagination v-model="page_num" :total-rows="totalRows" :per-page="page_size"
                                        align="fill" size="sm" class="my-0"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TableDetail from '../../../components/MarketPlace/CheckStatus/DetailStatus.vue';
export default {
    name: 'CheckOrders',
    components: {
        TableDetail
    },
    data() {
        return {
            mode: "ORDERNO",
            find: '',
            isLoading: false,
            items: [],
            itemlist: [],
            totalRows: 0,
            page_size: 25,
            page_size_list: [25, 50, 100],
            page_num: 1,
            totalRows: 0,
            fields: [
                { key: 'Name', lable: 'Name', class: "text-left" },
                { key: 'RunStatus', lable: 'RunStatus', class: "text-center" },
                { key: 'start_execdate', lable: 'Start Execute', class: "text-center" },
                { key: 'stop_execdate', lable: 'Stop Execute', class: "text-center" },
                { key: 'show_details', lable: 'More', class: "text-center" }
            ],
        }
    },
    async mounted() {
        await this.initTable();
        this.intervalId = setInterval(async () => {
            await this.initTable();
        }, 3000000);
    },
    destroyed() {
        // Clear the interval when the component is destroyed to avoid memory leaks
        clearInterval(this.intervalId);
    },
    methods: {
        async initTable() {
            try {
                let getAPI = await this.$serviceAPI.call_API(
                    "post",
                    `market-place/getJobsList`,
                    [],
                    1
                );
                this.items = getAPI.data ?? []
                this.itemlist = getAPI.data ?? [];
                this.totalRows = this.items.length;
            } catch (error) {
                console.log(error);
            }
        },
        rowClass(item) {
            return item.RunStatus === "Running" ? 'table-success' : (item.RunStatus === "Failed" || item.RunStatus === "Cancelled") ? 'table-danger' : '';
        }
    },

}
</script>