<template>
    <div class="row p-1 m-0 bg-BNB">
        <div class="row col-12 col-xl-12 pb-2">
            <div class="col-12 col-xl-4 text-center bg-BNB border-b-DC p-1">
                <span class="text-center">Subject</span>
            </div>
            <div class="col-12 col-xl-4 text-center bg-BNB border-b-DC p-1">
                <span class="text-center">Quantity</span>
            </div>
            <div class="col-12 col-xl-4 text-center bg-BNB border-b-DC p-1">
                <span class="text-center">System</span>
            </div>
        </div>
        <!-- {{ detail }} -->
        <div class="row col-12 col-xl-12" v-for="(item, index) in detail" :key="index">
            <div class="col-12 col-xl-4 text-left">
                <span class="text-center">{{ item.Subject }}</span>
            </div>
            <div class="col-12 col-xl-4 text-center">
                <span class="text-center">{{ item.Quantity }}</span>
            </div>
            <div class="col-12 col-xl-4 text-center">
                <span class="text-center">{{ item.System }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TableDetail',
    props: ['items'],
    data() {
        return {
            itemlist: [],
            system: '',
            detail: []
        }
    },
    async created() {
        this.itemlist = this.items;
        this.system = this.itemlist.item.Name.split(' ')[0] ?? '';
        await this.initData()
    },
    methods: {
        async initData() {
            try {
                let payloads = {
                    system: this.system
                }
                let getAPI = await this.$serviceAPI.call_API(
                    "post",
                    `market-place/getCheckStatus`,
                    payloads,
                    1
                );
                if (getAPI && getAPI.status_code === 200) {
                    this.detail = getAPI.data ?? [];
                } else {
                    this.detail = [];
                }
            } catch (error) {
                console.log(error);
            }
        }
    },
}
</script>
